var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outer-style" }, [
    _c(
      "div",
      [
        _vm._m(0),
        _c("el-button", { on: { click: _vm.getParams } }, [
          _vm._v("获取序列化参数"),
        ]),
        _vm._v(" " + _vm._s(_vm.text) + " "),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("form", { attrs: { id: "myform" } }, [
      _vm._v(" Last Name: "),
      _c("input", { attrs: { type: "text", name: "first_name" } }),
      _c("br"),
      _vm._v(" Last Name:"),
      _c("input", { attrs: { type: "text", name: "last_name" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }